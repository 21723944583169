import { addLocaleData } from "react-intl";
import ruLang from "./entries/ru-RU";
import azLang from "./entries/az-AZ";

const AppLocale = {
  ru: ruLang,
  az: azLang,
};
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.az.data);

export default AppLocale;
