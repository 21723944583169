import appLocaleData from "react-intl/locale-data/az";

const azLang = {
  messages: {
  
  },
  locale: "az-AZ",
  data: appLocaleData,
};

export default azLang;
