import React from "react";
import ReactDOM from "react-dom";
import App from "./container";
import Logo from "./assets/img/logo.svg";

import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import AppLocale from "./translations";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.css";
import "./assets/font-awesome/css/font-awesome.min.css";

ReactDOM.render(
  <React.StrictMode>
    <>
      {!isMobile && <App />}
      {isMobile && (
        <div className="container d-flex justify-content-center align-items-center " style={{ minHeight: '100vh', flexDirection: 'column' }}>
          <img width="150" height="200" src={Logo} />
          <p>Please visit to website from Desktop</p>
          <p>
            Tel: +994 12 409 09 94 <br />
            Mob: +994 50 500 09 94 <br />
          </p>
          <p>
            Emial: office@asuniser.az
            <br />
            Emial: info@asuniser.az
          </p>
        </div>
      )}
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
