import React, { Suspense, memo } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import loadable from "@loadable/component";

const Layout = loadable(() => import("../layout"));
const Home = loadable(() => import("./Home"));
const Services = loadable(() => import("./Services"));
const Contact = loadable(() => import("./Contact"));

function Main() {
  return (
    <Suspense fallback={<div className="loader" />}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} />} />
            <Route
              path="/services"
              render={(props) => <Services {...props} />}
            />
            <Route path="/contact" render={(props) => <Contact {...props} />} />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
}

export default memo(Main);
